import Hamburger from "../../assets/images/iconmonstr-menu-lined.svg";
import React from "react";
const NavigationHamburger = React.forwardRef((props, ref) => (
  <section className="ham-section">
    <img
      className="ham-menu"
      src={Hamburger}
      alt="hamburger menu"
      ref={ref}
      onClick={() => {
        props.ToggleMenu();
      }}
    />
  </section>
));
export default NavigationHamburger;
