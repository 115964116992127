import Zoe from "../../assets/images/zoe.jpg";

const HeaderPreview = () => {
  return (
    <article className="header">
      <br></br>
      <br></br>
      <br></br>
      {/* <div style={{textAlign: 'center'}}> <img style={{display: 'inline'}} width="150" src={Zoe} alt="logo"></img></div> */}
      <h2 className="header-font darkgray-text">
        👋 Hi, I'm Zoe.<br></br>
      </h2>
       <p className="gray-text">I am a Boston-based software engineer, creative technologist, and educator. I have been programming and teaching others how to code since 2012, most recently lecturing an introductory course in python at NYU. I studied computer science and artifical intelligence at Stanford, where my research focused on computer vision for social good. Since then, I have worked at Google, Yelp, and Meta on content moderation at scale, online news identification & ranking, and community engagement platforms. Currently, I am an engineering leader at <a href="https://column.us/">Column</a>, a public benefit corporation building software to facilitate the public notice process in local newspapers across the US.</p>
      <p className="gray-text"> In 2023, I completed a Master's in Interactive Media Arts from NYU Tisch. My projects centered around creatively conveying the potentional harms of modern technology to the public. Lately, I have been interested in how the collection, movement, and regulation of data impacts our digital rights. </p>

    </article>
  );
};

export default HeaderPreview;
