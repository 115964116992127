const AcademicWork = () => {
  return (
    <section>
      <h2 className="title-font pink-text">Academic Work</h2>
      <p className="gray-text">
      <br></br> 
      <h3 className="title-font pink-text">Research</h3>
      - <a href="https://cs229.stanford.edu/proj2016/report/ShroffLimonchikRobert-PredictingImaginedMetersInMusicalPattersFromMEGData-report.pdf"><i>Predicting Imagined Meters in
Musical Patterns from MEG Data</i></a> (2016)
      <br></br>
      - <a href="http://cs231n.stanford.edu/reports/2017/pdfs/227.pdf"><i>Lip Reading Word Classification</i></a> (2017)
      <br></br>
      - <a href="https://cs230.stanford.edu/projects_spring_2018/reports/8289680.pdf"><i>Deep Learning & the Opioid Epidemic: Estimating Opioid-Related Mortality Risk in US Counties with Twitter Data</i></a> (2018)
      <br></br>
      - <a href="https://arxiv.org/abs/1905.02196"><i>Mapping Missing Populations in Rural India: A Deep Learning Approach With Satelite Imagery</i></a> (2018)
      <br></br>
      After publishing my satelite research in the AI, Ethics, and Society sub-conference of <a href="https://aaai.org/">AAAI</a>, I also served as a program committee member for a number of years reviewing journal publication applications.    
       <br></br> 
       <br></br> 
       <h3 className="title-font pink-text">Teaching</h3>
      - Research Assistant at the <a href="https://sites.cc.gatech.edu/campice/">Georgia Tech Institute For Computing Education</a> (2012-2013)
        <br></br>    
      - <a href="https://cs198.stanford.edu/cs198/Default.aspx">Stanford Senior Section Leader</a> (2013-2017)
        <br></br>      
      - Private Scratch Tutor for <a href="https://breakoutmentors.com/">Breakout Mentors</a> (2014-2017)
        <br></br>      
      - Stanford Teaching Assistant in CS X Ethics and Computer Systems in C (2017-2018)
        <br></br>
      - <a href="https://www.microsoft.com/en-us/teals">Microsoft TEALS AP Computer Science Volunteer</a> (2020-2021)
        <br></br>
      - <a href="https://cs.nyu.edu/dynamic/courses/schedule/?semester=fall_2022">NYU Introduction to Programming in Python Adjunct Lecturer</a> (2022)
      </p>
    </section>
  );
};
export default AcademicWork;
