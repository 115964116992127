const Footer = () => {
  return (
    <article className="footer">
      <p>
       © Zoe-Alanah Robert 2024
      </p>
    </article>
  );
};

export default Footer;
