import Instagram from "../../assets/images/socials/iconmonstr-instagram-13.svg";
import Github from "../../assets/images/socials/iconmonstr-github-3.svg";
import Linkedin from "../../assets/images/socials/iconmonstr-linkedin-3.svg";
const NavigationSocials = () => {
  return (
    <section className="social-media">
      <a
        href="https://www.linkedin.com/in/zoerobert/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Linkedin} alt="linkedin" />
      </a>
      <a
        href="https://github.com/zrobert7"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Github} alt="github" />
      </a>
      <a
        href="https://www.instagram.com/z0ealanah/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Instagram} alt="instagram" />
      </a>
    </section>
  );
};

export default NavigationSocials;
