import DownloadIcon from "../../assets/images/iconmonstr-download-20.svg";
import Resume from "../../assets/documents/ZoeAlanahRobert_RESUME.pdf";

const DownloadResume = () => {
  return (
    <section>
      <a className="download-btn" href={Resume} download="Zoe-Alanah-Robert">
        <img
          src={DownloadIcon}
          alt="Resume download button"
          className="download-img"
        />
        Download Resume
      </a>
    </section>
  );
};

export default DownloadResume;
