import { Footer } from "../../components/index.js";
import DownloadResume from "./DownloadResume.js";
import AcademicWork from "./AcademicWork.js";
import ScrollButton from "../../helpers/ScrollToTop";
import { useEffect } from "react";

const Academic = () => {
  useEffect(() => {
    document.title = "About | Zoe-Alanah Robert";
  })
  return (
    <article className="about-main">
      <AcademicWork />
      <DownloadResume />
      <ScrollButton />
      <Footer />
    </article>
  );
};

export default Academic;
