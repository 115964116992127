/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import Portfolio1 from "../../data/deleatme.png";
import Portfolio2 from "../../data/deleteme2.png";
import Portfolio3 from "../../data/grub.png";
import Portfolio4 from "../../data/berlin.JPG";



const Projects = () => {
  return (
    <div>
      <h3 className="title-font pink-text"><b>Del(eat) Me</b> - <i>2023, Shanghai, interactive sculpture</i></h3>
      <p className="gray-text">
      A reproduction of my most vulnerable internet search history etched into bread. The second iteration of a two-part series, Del(eat) Me experiments with legible data and a more playful form of deletion: eating (<a href="https://itp.nyu.edu/lowres/thesisarchive2023/?zoe-alanah-robert">more</a>)
      </p>
      <img src={Portfolio1} width="600"/>
      <br/>
      <br/>
      <h3 className="title-font pink-text"><b>Delete Me</b> - <i>2023, NYC, interactive sculpture</i></h3>
      <p className="gray-text">
      "Delete Me” is an exploration in data collection, digital rights, and the catharsis of participatory destruction. Participants are invited to help me destroy over 100GB of my downloaded & re-printed data from dozens of websites and data brokers. (<a href="https://itp.nyu.edu/lowres/thesisarchive2023/?zoe-alanah-robert">more</a>)
      </p>
      <img src={Portfolio2} width="600"/>
      <br/>
      <br/>
      <h3 className="title-font pink-text"><b>Party like its 2012</b> - <i>2023, Berlin, webpage + firewalled wifi network</i></h3>
      <p className="gray-text">
      A wifi Network installation to help you party (surf the web) like its the end of the world. This network funnels all web traffic through the <a href="https://web.archive.org/">WayBack Machine</a> (<a href="https://tisch.nyu.edu/itp/news/spring-2023/nyu-ima-low-res-group-show-at-art-studio-weise7-in-berlin-">more</a>)
      </p>
      <img src={Portfolio4} width="600"/>
      <br/>
      <br/>
      <h3 className="title-font pink-text"><b>Grubhub Dark Mode</b> - <i>2022, NYC, webpage</i></h3>
      <p className="gray-text">
      A parody website exploring our relationships with Food and Sex - more specifically, the expanding food delivery service industry and that of internet pornography. 
      The brands that operate in these high speed, excess desire marketplaces have become cornerstones of culture. (<a href="http://www.darkmodegrub.com/">www.darkmodegrub.com</a>)
      </p>
      <img src={Portfolio3} width="600"/>
      <br/>
      <br/>
      <h3 className="title-font pink-text"><b>Boob iT</b> - <i>2022, NYC, interactive sculpture</i></h3>
      <p className="gray-text">
      Simultaneously a playful toy and an observation on the boundaries of touch, Boob iT places an unexpected spin on the classic kids toy, <a href="https://en.wikipedia.org/wiki/Bop_It">Bop iT</a> (<a href="https://www.youtube.com/shorts/XEa57fXdb2A">demo</a>)
      </p>
      <br/>
      <h3 className="title-font pink-text"><b>The Angry Feminist</b> - <i>2016, San Francisco, wearable + audio</i></h3>
      <p className="gray-text">
      To augment the rage I felt as a woman listening to the news during the 2016 U.S. election cycle (<a href="https://youtu.be/2Dh4LFL14Cw">demo</a>)
      </p>
    </div>
  );
};

export default Projects;
